exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-backstage-bites-js": () => import("./../../../src/pages/backstage-bites.js" /* webpackChunkName: "component---src-pages-backstage-bites-js" */),
  "component---src-pages-backstage-docker-image-js": () => import("./../../../src/pages/backstage/docker-image.js" /* webpackChunkName: "component---src-pages-backstage-docker-image-js" */),
  "component---src-pages-backstage-plugins-js": () => import("./../../../src/pages/backstage/plugins.js" /* webpackChunkName: "component---src-pages-backstage-plugins-js" */),
  "component---src-pages-backstage-spotify-js": () => import("./../../../src/pages/backstage-spotify.js" /* webpackChunkName: "component---src-pages-backstage-spotify-js" */),
  "component---src-pages-backstage-weekly-js": () => import("./../../../src/pages/backstage-weekly.js" /* webpackChunkName: "component---src-pages-backstage-weekly-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-docs-api-catalog-js": () => import("./../../../src/pages/docs/api/catalog.js" /* webpackChunkName: "component---src-pages-docs-api-catalog-js" */),
  "component---src-pages-docs-api-plugins-js": () => import("./../../../src/pages/docs/api/plugins.js" /* webpackChunkName: "component---src-pages-docs-api-plugins-js" */),
  "component---src-pages-docs-api-techinsights-js": () => import("./../../../src/pages/docs/api/techinsights.js" /* webpackChunkName: "component---src-pages-docs-api-techinsights-js" */),
  "component---src-pages-docs-api-templates-js": () => import("./../../../src/pages/docs/api/templates.js" /* webpackChunkName: "component---src-pages-docs-api-templates-js" */),
  "component---src-pages-docs-details-create-proxy-js": () => import("./../../../src/pages/docs/details/create-proxy.js" /* webpackChunkName: "component---src-pages-docs-details-create-proxy-js" */),
  "component---src-pages-docs-getting-started-adding-a-catalog-item-js": () => import("./../../../src/pages/docs/getting-started/adding-a-catalog-item.js" /* webpackChunkName: "component---src-pages-docs-getting-started-adding-a-catalog-item-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-integrations-js": () => import("./../../../src/pages/docs/integrations.js" /* webpackChunkName: "component---src-pages-docs-integrations-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-free-trial-js": () => import("./../../../src/pages/free-trial.js" /* webpackChunkName: "component---src-pages-free-trial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-pending-js": () => import("./../../../src/pages/installation-pending.js" /* webpackChunkName: "component---src-pages-installation-pending-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-product-access-control-js": () => import("./../../../src/pages/product/access-control.js" /* webpackChunkName: "component---src-pages-product-access-control-js" */),
  "component---src-pages-product-catalog-js": () => import("./../../../src/pages/product/catalog.js" /* webpackChunkName: "component---src-pages-product-catalog-js" */),
  "component---src-pages-product-documentation-js": () => import("./../../../src/pages/product/documentation.js" /* webpackChunkName: "component---src-pages-product-documentation-js" */),
  "component---src-pages-product-scaffolder-js": () => import("./../../../src/pages/product/scaffolder.js" /* webpackChunkName: "component---src-pages-product-scaffolder-js" */),
  "component---src-pages-product-tech-insights-js": () => import("./../../../src/pages/product/tech-insights.js" /* webpackChunkName: "component---src-pages-product-tech-insights-js" */),
  "component---src-pages-purchase-index-js": () => import("./../../../src/pages/purchase/index.js" /* webpackChunkName: "component---src-pages-purchase-index-js" */),
  "component---src-pages-purchase-success-js": () => import("./../../../src/pages/purchase/success.js" /* webpackChunkName: "component---src-pages-purchase-success-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-security-responsible-disclosure-js": () => import("./../../../src/pages/security/responsible-disclosure.js" /* webpackChunkName: "component---src-pages-security-responsible-disclosure-js" */),
  "component---src-pages-tailwind-404-js": () => import("./../../../src/pages/tailwind/404.js" /* webpackChunkName: "component---src-pages-tailwind-404-js" */),
  "component---src-pages-whitepapers-managed-vs-self-hosted-backstage-tsx": () => import("./../../../src/pages/whitepapers/managed-vs-self-hosted-backstage.tsx" /* webpackChunkName: "component---src-pages-whitepapers-managed-vs-self-hosted-backstage-tsx" */),
  "component---src-templates-backstage-bite-js": () => import("./../../../src/templates/BackstageBite.js" /* webpackChunkName: "component---src-templates-backstage-bite-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/CaseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-change-set-js": () => import("./../../../src/templates/ChangeSet.js" /* webpackChunkName: "component---src-templates-change-set-js" */),
  "component---src-templates-changelog-list-js": () => import("./../../../src/templates/ChangelogList.js" /* webpackChunkName: "component---src-templates-changelog-list-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/Doc.js" /* webpackChunkName: "component---src-templates-doc-js" */),
  "component---src-templates-legal-notice-js": () => import("./../../../src/templates/LegalNotice.js" /* webpackChunkName: "component---src-templates-legal-notice-js" */),
  "component---src-templates-plugin-js": () => import("./../../../src/templates/Plugin.js" /* webpackChunkName: "component---src-templates-plugin-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

